import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { AddToRegisterModalViewModelQuery, AddToRegisterModalViewModelQueryVariables } from '../../generated/graphql';
import { scheduledClassChildFieldsFragment } from '../../models/ScheduledClassChild';
import { childFieldsFragment } from '../../models/Child';

/**
 * Get all data required for the ScheduledClassesChildViewMode component from the api.
 * @param options
 */
export function useAddToRegisterModalViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<AddToRegisterModalViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<AddToRegisterModalViewModelQuery, AddToRegisterModalViewModelQueryVariables>(
        gql`
        query addToRegisterModalViewModelQuery {
            items: children {
                ...childFields
            }

            memberProfiles: profiles {
                id
                firstName
                lastName
                primaryPhone
            }

            scheduledClassChildren {
                ...scheduledClassChildFields
            }
        }

        ${childFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        `,
        {

            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}
import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { PaymentFieldsFragment } from '../generated/graphql';
import { PaymentState } from './constants/PaymentState';
import moment from "moment";
import { TaxRates } from './constants/TaxRates';

export const paymentFieldsFragment = gql`
    fragment paymentFields on Payment {
        id
        name
        createdDate
        paidDate
        failedDate
        paymentState
        totalPreDiscountAmountGross
        totalAmountGross
        totalAmountTaxPercentage
        paidAmountGross
        paidAmountTaxPercentage
        archived
        childId
        memberId
        stripePaymentIntentId
        allStripePaymentIntentIds
        isCombinedPayment
        combinedOnPaymentId
        scheduledClassId
        termId
        numberOfLessons
        costPerLesson
        sendReenrolmentReminderDate
        sentReenrolmentReminder
        sendReenrolmentFinalReminderDate
        sentReenrolmentFinalReminder
        oldScheduledClassId
    }
`;

export type Payment = Omit<PaymentFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const paymentDefaultValues = (): Partial<Payment> => ({
    id: Guid.newGuid(),
    name: '',
    createdDate: moment().toISOString(),
    paidDate: null,
    failedDate: null,
    paymentState: PaymentState.Unpaid,
    totalAmountGross: 0,
    totalAmountTaxPercentage: TaxRates.IrelandVatRate,
    paidAmountGross: 0,
    paidAmountTaxPercentage: 0,
    archived: false,
    childId: null,
    memberId: undefined,
    stripePaymentIntentId: '',
    allStripePaymentIntentIds: '',
    isCombinedPayment: false,
    combinedOnPaymentId: null,
    sentReenrolmentReminder: false,
    sentReenrolmentFinalReminder: false,
    oldScheduledClassId: null,
});
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Waypoint } from "react-waypoint";
import { Alert, Button, ButtonDropdown, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Row, Spinner } from "reactstrap";
import { useToggleState, useToggleStateArray } from "use-toggle-state";
import { BlobUploadService } from "../../api/main/blobReferences/BlobUploadService";
import { useGiftVouchers } from "../../api/main/giftVouchers/useGiftVouchers";
import { createServiceProvider } from "../../configure/configureServices";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { FileUploadButton } from "../shared/fileUploadButton/FileUploadButton";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * List of GiftVouchers
 */
export const GiftVouchersList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            items: _items
        }, isLoading, errors: loadErrors, fetchMore, hasMore, refresh
    } = useGiftVouchers({ pageSize: undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Filtering active
    const [filterConsumedGiftVouchers, setFilterConsumedGiftVouchers] = useState<string | null | undefined>('all');

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Filtering
    const items = useMemo(() => {
        let ret = (_items ?? []);

        // Filter by the searchterm
        // Filters - MemberName, TermName, Value, or SerialNumber
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                (item.value?.toString() ?? '')?.indexOf(lowerSearch) >= 0
                || (item.serialNumber ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        // Filter by Active, Consumed, or Expired
        switch (filterConsumedGiftVouchers) {
            case 'active':
                return ret.filter(it => it.isConsumed === false && it.expiryDate > moment().toISOString()); // Active are not consumed and not expired
            case 'consumed':
                return ret.filter(it => it.isConsumed === true);
            case 'expired':
                return ret.filter(it => it.isConsumed === false && it.expiryDate < moment().toISOString()); // We don't want to see Expired vouchers that are also consumed
            case 'multiUse':
                return ret.filter(it => it.isMultiUse === true);
            default:
                return ret;
        }

    }, [filterConsumedGiftVouchers, _items, search]);

    // Alert for successful file upload
    const [fileUploadResult, setFileUploadResult] = useState<{ count: number, duplicatedSerialNumbers: string[]; }>({ count: 0, duplicatedSerialNumbers: [] });
    const [alertVisible, setAlertVisible] = useToggleState(false);

    // Handle uploading of GiftVouchers from CSV or XLSX files
    const [uploadEventHandler, { isExecuting: isUploading, errors: uploadingErrors, successfullyExecuted: uploadingSuccessful }] = useAsyncCallback(async (files: FileList | null) => {
        if (!!files) {
            let blobUploadService = new BlobUploadService('/api/blobs');
            let blobReference = await blobUploadService.upload(files);
            if (!!blobReference) {
                let services = createServiceProvider();
                let api = services.services().apiFetch();
                let result: { count: number, duplicatedSerialNumbers: string[]; } = await api.get(`/api/giftvoucherimporter/Import/${blobReference.id}`);

                // Result will be a count of the number of rows added to the GiftVoucher table. We can use this to let the user know how many were added.
                if (result) {
                    setFileUploadResult(result);
                    setAlertVisible(true);
                }

                await refresh();
            }
        }
    }, [refresh, setFileUploadResult, setAlertVisible]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('giftVouchersList.title', 'Gift vouchers')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="primary" outline={filterConsumedGiftVouchers !== 'all'} onClick={() => setFilterConsumedGiftVouchers('all')}>
                                    {t('common.all', 'All')}
                                </Button>
                                <Button color="primary" outline={filterConsumedGiftVouchers !== 'active'} onClick={() => setFilterConsumedGiftVouchers('active')}>
                                    {t('giftVouchersList.filter.active', 'Active')}
                                </Button>
                                <Button color="primary" outline={filterConsumedGiftVouchers !== 'consumed'} onClick={() => setFilterConsumedGiftVouchers('consumed')}>
                                    {t('giftVouchersList.filter.consumed', 'Consumed')}
                                </Button>
                                <Button color="primary" outline={filterConsumedGiftVouchers !== 'expired'} onClick={() => setFilterConsumedGiftVouchers('expired')}>
                                    {t('giftVouchersList.filter.expired', 'Expired')}
                                </Button>
                                <Button color="primary" outline={filterConsumedGiftVouchers !== 'multiUse'} onClick={() => setFilterConsumedGiftVouchers('multiUse')}>
                                    {t('giftVouchersList.filter.multiUse', 'Multi use')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary" outline>
                                            <FontAwesomeIcon icon="plus" /><> {t('giftVouchersList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                    <FileUploadButton color="primary" onUpload={uploadEventHandler} isExecuting={isUploading}
                                        executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>} >
                                        {t('giftVouchersList.uploadCSV', 'Upload CSV')}
                                    </FileUploadButton>
                                    <Button color="primary" outline>
                                        <a href="/examples/gift-voucher-upload-example.xlsx" download="gift-voucher-upload-example2.xlsx" style={{ textDecoration: 'none' }}>
                                            {t('giftVouchersList.downloadCSVTemplate', 'Download CSV template')}
                                        </a>
                                    </Button>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        uploadingErrors
                    ]} />

                <ConditionalFragment showIf={uploadingSuccessful && !!fileUploadResult && !!alertVisible}>
                    <Alert style={{ textAlign: 'center' }} isOpen={true} toggle={() => setAlertVisible(false)}>
                        {t('giftVouchersList.uploadSuccess.message', 'Successfully added {{count}} gift voucher{{pluralise}}.', { count: fileUploadResult.count, pluralise: (fileUploadResult.count > 1 || fileUploadResult.count === 0) ? 's' : '' })}
                        <ConditionalFragment showIf={fileUploadResult.duplicatedSerialNumbers.length > 0}>
                            <ListGroup>
                                <p>{t('giftVouchersList.duplicateGiftVoucher.message', 'The following gift vouchers already exist in the database and could not be added.')}</p>
                                {fileUploadResult.duplicatedSerialNumbers?.map(duplicatedSerialNumber => (
                                    <ListGroupItem color="success">
                                        <span>{t('common.serialNumber', 'Serial number')}: {duplicatedSerialNumber}</span>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </ConditionalFragment>
                    </Alert>
                </ConditionalFragment>

                <CardsOrTable
                    viewMode={"table"}
                    items={items}
                    tableHeadings={[
                        t('giftVouchers.dateAdded.heading', 'Date added'),
                        t('giftVouchers.serialNumber.heading', 'Serial number'),
                        t('giftVouchers.value.heading', 'Value'),
                        t('giftVouchers.multiUse.heading', 'Mulit use?'),
                        t('giftVouchers.expiryDate.heading', 'Expiry date'),
                        t('giftVouchers.isConsumed.heading', 'Consumed?'),
                        t('giftVouchers.reason.heading', 'Reason'),
                    ]}
                    columns={[
                        // Date added
                        (item, view) => {
                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.dateAdded) });
                        },

                        // Serial number
                        (item, view) => {
                            return item.serialNumber;
                        },

                        // Value
                        (item, view) => {
                            return item.value.toFixed(2);
                        },

                        // Multi use
                        (item, view) => {
                            return item.isMultiUse ? t('common.yes', 'Yes') : t('common.no', 'No');
                        },

                        // Expiry date
                        (item, view) => {
                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.expiryDate) });
                        },

                        // Is consumed
                        (item, view) => {
                            return item.isConsumed ? t('common.yes', 'Yes') : t('common.no', 'No');
                        },

                        // Reason
                        (item) => {
                            return item.reason ? item.reason : t('common.notApplicable', 'N/A');
                        }
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};
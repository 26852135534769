import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { useCancelBackgroundTaskMutation } from "../../api/main/backgroundTasks/useCancelBackgroundTaskMutation";
import { BackgroundTaskStatus } from "../../api/main/models/constants/BackgroundTaskStatus";
import { useStopReenrolmentViewModel } from "../../api/main/reenrolments/viewModels/useStopReenrolmentViewModel";
import { useStopReenrolmentMutation } from "../../api/main/stopReenrolments/useStopReenrolmentMutation";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { TwoValueSwitch } from "../shared/twoValueSwitch/TwoValueSwitch";
import { AddToStopReenrolmentModal } from "./AddToStopReenrolmentModal";

export interface StopReenrolmentModalResultMessages {
    errorMessage: string | undefined | null, warningMessage: string | undefined | null, successMessage: string | undefined | null;
}

export const StopReenrolment = () => {
    const { t } = useTranslation();

    const {
        data: { nextTerm, latestBackgroundTask: _latestBackgroundTask, },
        isLoading,
        errors: loadErrors,
        refresh,
    } = useStopReenrolmentViewModel();

    // We're only counting the _latestBackgroundTask as relevant if it covered this term.
    const latestBackgroundTask = useMemo(() => {
        // If we don't have a task, just return it.
        if (!_latestBackgroundTask) {
            return _latestBackgroundTask;
        }

        // Check if the arguments contain our nextTerm id.  If it does not, exclude the task from consideration.
        if ((_latestBackgroundTask.argumentsJson?.toLowerCase() ?? '').indexOf(nextTerm?.id?.toLowerCase() ?? '') === -1) {
            return undefined;
        }

        // If we get here the task is for our term, so use it.
        return _latestBackgroundTask;
    }, [_latestBackgroundTask, nextTerm]);

    // Toggle to know that the user has confirmed they want to continue.
    const [iveReadChecked, toggleIveRead] = useToggleState();

    // Set the default filter state to the current term.
    // While we're on this screen, we want to refresh the task status on a short interval.
    // NOTE we don't limit this to happening only when we think we have relevant task, because if someone else triggers it we can
    // still update the screen.
    useEffect(() => {
        // Start an interval timer.
        const timer = setInterval(() => {
            refresh();
        }, 20 * 1000);

        // Cleanup function.
        return () => clearInterval(timer);
    }, [refresh]);

    // Allow the cancelling of the current task.
    const [cancelBackgroundTaskMutation, { errors: cancelBackgroundTaskMutationErrors }] = useCancelBackgroundTaskMutation();
    const [cancelBackgroundTask, { isExecuting: isCancellingBackgroundTask, errors: cancelBackgroundTaskErrors }] = useAsyncCallback(async () => {
        // If we don't have a task, do nothing.
        if (!latestBackgroundTask) {
            return;
        }

        // Try cancel on the server.  This will only work if we haven't started executing the task.
        await cancelBackgroundTaskMutation(latestBackgroundTask.id);

        refresh();
    }, [latestBackgroundTask, cancelBackgroundTaskMutation, refresh]);

    // Showing of the StopReenrolmentModal
    const [stopReenrolmentModalIsOpen, _toggleStopReenrolmentModal] = useToggleState();
    const toggleReenrolmentModal = useCallback(() => {
        _toggleStopReenrolmentModal();
    }, [_toggleStopReenrolmentModal]);

    // Message state from the various actions.
    const [actionMessages, setActionMessages] = useState<StopReenrolmentModalResultMessages>({ errorMessage: undefined, warningMessage: undefined, successMessage: undefined });

    // Manage the SelectedClassIds
    const [selectedClassIds, setSelectedClassIds] = useState<Array<string>>([]);

    // Handle adding classes for re-enrolment
    const [onReenrolmentModalClosed, { isExecuting: isAddingClassesForReenrolment, errors: addClassesForReenrolmentErrors }] = useAsyncCallback(async (event: { selectedIds: Array<string>, cancelled: boolean; }) => {
        if (event.cancelled || !event.selectedIds || !event.selectedIds.length) {
            return;
        }

        // Set the state for the returned classIds
        setSelectedClassIds(event.selectedIds);
    }, [setSelectedClassIds, t]);

    // Perform the reenrolment process by starting the task on the server.
    const [stopReenrolmentMutation, { errors: stopReenrolmentMutationErrors, }] = useStopReenrolmentMutation();
    const [stopReenrolment, { isExecuting: isStartingReenrolment, errors: stopReenrolmentErrors, }] = useAsyncCallback(async () => {
        // If we don't have a term to work on, do nothing.
        if (!nextTerm || selectedClassIds.length < 1) {
            return;
        }

        // Start the reenrolment mutation, this will start a background task.
        await stopReenrolmentMutation(selectedClassIds, nextTerm.id);

        // Show a success message
        setActionMessages({
            successMessage: t('stopReenrolment.addClassesForStopReenrolment.message', '{{count}} {{classOrClasses}} {{haveOrHas}} been closed for re-enrolment', { count: selectedClassIds.length, classOrClasses: selectedClassIds.length === 1 ? 'class' : 'classes', haveOrHas: selectedClassIds.length === 1 ? 'has' : 'have' }),
            warningMessage: undefined,
            errorMessage: undefined,
        });

        // Clear the selected classIds
        setSelectedClassIds([]);
    }, [stopReenrolmentMutation, nextTerm, refresh, setActionMessages, selectedClassIds]);

    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('stopReenrolment.title', 'Close reenrolment process for {{ term }}', { term: nextTerm?.name })}
                            </h1>
                            <div className="text-muted">
                                {t(
                                    'stopReenrolment.subTitle',
                                    'The next term will start in {{weekCount, 0}} weeks on {{date, dddd MMMM Do YYYY}}',
                                    {
                                        date: moment(nextTerm?.startDate),
                                        weekCount: moment(nextTerm?.startDate).diff(moment(), 'weeks')
                                    })}
                            </div>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadErrors,
                    stopReenrolmentErrors, stopReenrolmentMutationErrors,
                    cancelBackgroundTaskErrors, cancelBackgroundTaskMutationErrors,
                    addClassesForReenrolmentErrors
                ]} />

               <ConditionalFragment showIf={!!selectedClassIds.length}>
                    <Alert color="warning">
                        {t('stopReenrolment.addClassesForReenrolment.warningMessage', '{{count}} {{classOrClasses}} {{haveOrHas}} been selected to close re-enrolments', { count: selectedClassIds.length, classOrClasses: selectedClassIds.length === 1 ? 'class' : 'classes', haveOrHas: selectedClassIds.length === 1 ? 'has' : 'have' })}
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={
                    !!actionMessages.errorMessage ||
                    !!actionMessages.warningMessage ||
                    !!actionMessages.successMessage
                }>
                    <Alert color={
                        actionMessages.errorMessage ? 'danger' :
                            actionMessages.warningMessage ? 'warning' : 'success'
                    }>
                        <ConditionalFragment showIf={!!actionMessages.successMessage}>
                            {actionMessages.successMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!actionMessages.warningMessage}>
                            {actionMessages.warningMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!actionMessages.errorMessage}>
                            {actionMessages.errorMessage}
                        </ConditionalFragment>
                    </Alert>
                </ConditionalFragment>

                {
                    latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Queued ? (
                        <Alert color="info">
                            <Row>
                                <Col xs="auto" style={{ fontSize: '1.5rem', }}>
                                    <FontAwesomeIcon icon="clock" />
                                </Col>
                                <Col>
                                    <p style={{ fontWeight: 'bold', }}>
                                        {t('stopReenrolment.taskAlert.queued.p1', 'Close re-enrolement for next term is queued on to begin processing as soon as possible.')}
                                    </p>
                                    <p>
                                        {t('stopReenrolment.taskAlert.queued.p2', 'The close re-enrolement task is likely to take some time. This screen will be updated automatically. You do not need to wait on this screen for the task to run it will continue in the background as you do other things.')}
                                    </p>
                                </Col>
                                <Col xs={12} md="auto">
                                    <ButtonAsync color="info" outline
                                        isExecuting={isCancellingBackgroundTask}
                                        executingChildren={<><Spinner size="sm" /> {t('stopReenrolment..executingText', 'Cancelling...')}</>}
                                        onClick={cancelBackgroundTask}
                                    >
                                        <FontAwesomeIcon icon="times" />
                                        <> </>
                                        {t('stopReenrolment.cancelButton.text', 'Cancel')}
                                    </ButtonAsync>
                                </Col>
                            </Row>
                        </Alert>
                    ) : latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Executing ? (
                        <Alert color="info">
                            <Row>
                                <Col xs="auto" style={{ fontSize: '1.5rem', }}>
                                    <FontAwesomeIcon icon="hourglass" />
                                </Col>
                                <Col>
                                    <p style={{ fontWeight: 'bold', }}>
                                        {t('stopReenrolment.taskAlert.executing.errors.p1', 'Close re-enrolement steps for next term are in progress.  Processing started at {{date, DD/MM/YYYY HH:mm}}.', { date: moment(latestBackgroundTask.startedDate) })}
                                    </p>
                                    <p>
                                        {t('stopReenrolment.taskAlert.executing.p2', 'The re-enrolement task is likely to take some time. This screen will be updated automatically. You do not need to wait on this screen for the task to run it will continue in the background as you do other things.')}
                                    </p>
                                </Col>
                            </Row>
                        </Alert>
                    ) : latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Completed && !!latestBackgroundTask.executionErrors ? (
                        <Alert color="danger">
                            <Row>
                                <Col xs="auto" style={{ fontSize: '1.5rem', }}>
                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                </Col>
                                <Col>
                                    <p style={{ fontWeight: 'bold', }}>
                                        {t('stopReenrolment.taskAlert.completed.errors.p1', 'Close re-enrolement for next term failed to complete. Processing was attempted between {{startDate, DD/MM/YYYY HH:mm}} and {{completedDate, DD/MM/YYYY HH:mm}}.', { startDate: moment(latestBackgroundTask.startedDate), completedDate: moment(latestBackgroundTask.completedDate), })}
                                    </p>
                                    <p>
                                        {t('stopReenrolment.taskAlert.completed.errors.p2', 'Error reason:')}
                                        <> </>
                                        {latestBackgroundTask.executionErrors}
                                    </p>
                                </Col>
                            </Row>
                        </Alert>
                    ) : null
                }

                <h5>{t('stopReenrolment.whatWillHappen.title', 'What will happen?')}</h5>
                <ul>
                    <li>{t('stopReenrolment.whatWillHappen.p1', 'All unpaid cub\'s will be removed from selected classes.')}</li>
                    <li>{t('stopReenrolment.whatWillHappen.p2', 'All unpaid payments will be removed from selected classes.')}</li>
                    <li>{t('stopReenrolment.whatWillHappen.p3', 'It will then email each member to advise that their place have been opened up to the waiting lists.')}</li>
                </ul>

                <Alert color="info">
                    <TwoValueSwitch
                        leftLabel=""
                        rightLabel={t('stopReenrolment.iveReadChecked.rightText', 'I confirm I have read and understood the above information and understand the impact of closing re-enrolments.')}
                        checked={iveReadChecked}
                        onChange={checked => toggleIveRead(checked)}
                    />
                </Alert>

                <Row>
                    <Col></Col>
                    <Col xs="auto">
                        <ButtonAsync color="primary" disabled={!iveReadChecked || latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Queued || !selectedClassIds.length}
                            isExecuting={isStartingReenrolment}
                            executingChildren={<><Spinner size="sm" /> {t('stopReenrolment.startButton.executingText', 'Starting...')}</>}
                            onClick={stopReenrolment}
                        >
                            <FontAwesomeIcon icon="circle-play" />
                            <> </>
                            {t('stopReenrolment.startButton.text', 'Close re-enrolements')}
                        </ButtonAsync>
                    </Col>
                </Row>

                {/* Re-enrolment Modal */}
                <FormGroup>
                    <Button color="primary" onClick={() => toggleReenrolmentModal()}>
                        <FontAwesomeIcon icon="plus" />
                        <> </>
                        {t('stopReenrolment.addClasses', 'Add classes')}
                    </Button>

                    <ConditionalFragment showIf={stopReenrolmentModalIsOpen}>
                        <StyledModal
                            isOpen={stopReenrolmentModalIsOpen}
                            toggle={toggleReenrolmentModal}
                            size="xl">
                            <AddToStopReenrolmentModal
                                toggle={toggleReenrolmentModal}
                                onClose={onReenrolmentModalClosed}
                                isSaving={isAddingClassesForReenrolment}
                            />

                        </StyledModal>
                    </ConditionalFragment>
                </FormGroup>
            </MainContainer>
        </>
    );
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Alert, Button, CardImg, CardImgOverlay, CardTitle, Col, Row } from "reactstrap";
import { useChild } from "../../api/main/children/useChild";
import { useLocationDistances } from "../../api/main/classLocations/useLocationDistances";
import { ClassLocation } from "../../api/main/models/ClassLocation";
import { ClassStage } from "../../api/main/models/ClassStage";
import { ClassSubStage } from "../../api/main/models/ClassSubStage";
import { ClassType } from "../../api/main/models/ClassType";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { ScheduledClassChild } from "../../api/main/models/ScheduledClassChild";
import { useScheduledClassesWithChildrenForMembersClassFinderViewModel } from "../../api/main/scheduledClasses/viewModels/useScheduledClassesWithChildrenForMembersClassFinderViewModel";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { joinScheduledClassConfig } from "../../configure/joinScheduledClassConfig";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useSearchParams } from "../../shared/useURLSearchParams";
import { JoinCourtesyClassMessages } from "../membersClassFinder/MembersClassFinderClass";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { ClassFinderCoreLocation } from "./ClassFinderCoreLocation";
import { HorizontalRule } from "../shared/horizontalRule/HorizontalRule";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { useProfile } from "../../api/main/profiles/useProfile";
import "./classFinderCore.scss";
import { ChildAbsence } from "../../api/main/models/ChildAbsence";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCourtesyClassCredit } from "../../api/main/courtesyClassCredits/useCourtesyClassCredit";
import { useScheduledClass } from "../../api/main/scheduledClasses/useScheduledClass";

export interface ScheduledClassWithRelationships extends ScheduledClass {
    _classStageId?: string | null | undefined,
    _classSubStageId?: string | null | undefined,

    _spacesAvailable?: number,
    _nextLessonDateWithSpacesAvailable?: string | null | undefined,

    classType?: ClassType | undefined,
    classStage?: ClassStage | undefined,
    classSubStage?: ClassSubStage | undefined,
    classLocation?: ClassLocation | undefined,

    allChildren?: Array<ScheduledClassChild>,
    allAbsences?: Array<ChildAbsence>;
}

export const ClassFinderForMembers = () => {
    const { childId } = useParams<{ childId: string | undefined; }>();
    const { t } = useTranslation();

    return (
        <ClassFinderCore
            title={t('classFinderForMembers.title', 'Class finder')}
            canJoinClass={true}
            childId={childId}
        />
    );
};

export const CourtesyClassFinder = () => {
    const { childId, classTypeId, courtesyClassCreditId } = useParams<{ childId: string | undefined, classTypeId: string | undefined, courtesyClassCreditId: string | undefined; }>();
    const { t } = useTranslation();

    return (
        <ClassFinderCore
            title={t('courtesyClassFinder.title', 'Courtesy class finder')}
            canJoinClass={false}
            canBookCourtesyClass={true}
            childId={childId}
            classTypeId={classTypeId}
            courtesyClassCreditId={courtesyClassCreditId}
        />
    );
};

export const SwapClassFinder = () => {
    const { childId, classTypeId, scheduledClassId } = useParams<{ childId: string | undefined, classTypeId: string | undefined, scheduledClassId: string | undefined; }>();
    const { t } = useTranslation();

    return (
        <ClassFinderCore
            title={t('swapClassFinder.title', 'Change class')}
            canJoinClass={false}
            canBookCourtesyClass={false}
            canSwapToClass={true}
            childId={childId}
            classTypeId={classTypeId}
            scheduledClassId={scheduledClassId}
        />
    );
};

export interface ClassFinderCoreProps {
    title: string,
    canJoinClass?: boolean,
    canBookCourtesyClass?: boolean,
    canSwapToClass?: boolean,

    childId: string | undefined,
    classTypeId?: string | undefined,
    courtesyClassCreditId?: string | undefined;
    scheduledClassId?: string | undefined;
}

/**
 * Component for FindingClasses, is used for Members and facilitates JoinClass, CourtesyCreditClassFinder, and SwapClassClassFinder
 */
export const ClassFinderCore = (props: ClassFinderCoreProps) => {
    const {
        title,
        //canJoinClass = false,
        canBookCourtesyClass = false,
        canSwapToClass = false,
        childId,
        classTypeId,
        courtesyClassCreditId,
        scheduledClassId
    } = props;
    const { t } = useTranslation();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();

    // Load the child - The member can be routed here from one of their children, if that is the case we want to load that child, so we can find suitable classes
    const {
        data: {
            model: storeChild
        }, isLoading: _isLoading, errors: loadErrors
    } = useChild(childId);

    // Load the supporting data
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            scheduledClassChildren,
            terms,
            childAbsences,
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useScheduledClassesWithChildrenForMembersClassFinderViewModel({
        includeChildAbsences: canBookCourtesyClass ? true : false,
    });

    const {
        data: {
            model: courtesyClassCredit
        }, isLoading: isLoadingCourtesyClassCredit, errors: courtesyClassCreditErrors
    } = useCourtesyClassCredit(courtesyClassCreditId);

    const {
        data: {
            model: courtesyClass
        }, isLoading: isLoadingCourtesyClass, errors: courtesyClassErrors
    } = useScheduledClass(courtesyClassCredit?.scheduledClassId);
    const isLoading = _isLoading || isLoadingSupportingData || isLoadingCurrentTerm || isLoadingCourtesyClassCredit || isLoadingCourtesyClass;

    const oldScheduledClassChildId = useMemo(() => {
        if (!scheduledClassId || !childId) return undefined;

        const scheduledClassChild = scheduledClassChildren?.find(it => it.scheduledClassId === scheduledClassId && it.childId === childId);

        return scheduledClassChild?.id;
    }, [scheduledClassId, childId, scheduledClassChildren]);

    // Terms Filter
    const [termFilter, setTermFilter] = useState<string | undefined>(currentTerm?.id);
    // Combine the data
    const allItems = useMemo(() => _allItems?.map(item => {
        // Depending on if we are looking at the current or next term, we want the special _classStageId and _classSubStageId to
        // be set to the relevant field.
        const _classStageId = item.currentClassStageId;
        const _classSubStageId = item.currentClassSubStageId;

        const _nextLessonDateWithSpacesAvailable = item.currentLessonNumber >= joinScheduledClassConfig.canJoinCurrentTermUntilLessonNumber ? item.nextLessonDateWithSpacesAvailableForNextTerm : item.nextLessonDateWithSpacesAvailableForCurrentTerm;

        // Follow the various lookups for related data.
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const activeClassStage = classStages?.find(it => it.id === _classStageId);
        const activeClassSubStage = classSubStages?.find(it => it.id === _classSubStageId);

        const allChildren = scheduledClassChildren?.filter(it => it.scheduledClassId === item.id);

        const today = moment().toISOString();
        const allAbsences = childAbsences?.filter(it => it.scheduledClassId === item.id && it.endDate > today);

        // Return everything as a single object.
        return {
            ...item,

            _classStageId,
            _classSubStageId,

            _nextLessonDateWithSpacesAvailable,

            classType,
            classLocation,
            classStage: activeClassStage,
            classSubStage: activeClassSubStage,

            allChildren,
            allAbsences
        };
    }), [_allItems, classTypes, classLocations, classStages, classSubStages, scheduledClassChildren, childAbsences]);

    // Manage the tab, classType and classLocation being displayed
    const {
        classType: classTypeParam,
        classLocation: classLocationParam,
        classAvailability: classAvailabilityParam,
        day: dayParam,
    } = useSearchParams();

    // Filter ClassTypes
    const [filterClassType, setFilterClassType] = useState<string | null | undefined>(classTypeParam ?? classTypeId ?? '');

    // Filter ClassLocations
    const [filterClassLocation, setFilterClassLocation] = useState<string | null | undefined>(classLocationParam ?? '');
    //const filterClassLocation = _filterClassLocation || classTypes?.find(it => true)?.id;

    // Filter Day
    const [filterDay, setFilterDay] = useState<string | null | undefined>(dayParam ?? '');

    // Filter Availability
    const [filterClassAvailability, setFilterClassAvailability] = useState<string>(classAvailabilityParam ?? 'all');

    // Keep the URL up to date with the filters
    //useReplaceSearchParamsEffect({ classType: filterClassType, dateOfBirth: storeChild?.dateOfBirth });

    const {
        data: {
            model: memberProfile
        }, errors: memberProfileLoadErrors
    } = useProfile(storeChild?.memberId ?? '');

    const {
        data: {
            items: locationDistances
        }, errors: locationDistancesLoadErrors
    } = useLocationDistances({ postcodeOrCity: memberProfile?.postcode.split(' ')[0] ?? '' });

    // Filtering
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Filter out the deferrals class location
        ret = ret.filter(it => it.classLocation?.name !== 'Deferrals - DO NOT REMOVE');
        const term = terms?.find(it => it.id === termFilter);

        // If the term is not found, or the term cannot self register, return an empty array.
        if (!term || (!term?.canSelfRegister && !canBookCourtesyClass)) return [];

        // Filtering ClassTypes
        if (filterClassType !== 'all' && filterClassType !== '') {
            ret = ret.filter(it => it.classTypeId === filterClassType);
        }

        // Filtering ClassLocations
        if (filterClassLocation !== 'all' && filterClassLocation !== '') {
            ret = ret.filter(it => it.classLocationId === filterClassLocation);
        }

        // Filtering the ClassType passed as props
        if (classTypeId) {
            ret = ret.filter(it => it.classTypeId === classTypeId);
        }

        // Filtering for Day
        if (filterDay !== 'all' && filterDay !== '') {
            ret = ret.filter(it => it.dayOfWeek === parseInt(filterDay ?? '0'));
        }

        // Filtering for Availability
        if (filterClassAvailability !== 'all' || canSwapToClass) {
            ret = ret.filter(it => it.maximumClassSize - it.allChildren.length >= 1);
        }

        // Filtering for Availability - Taking childAbsences into account
        if (canBookCourtesyClass) {
            ret = ret.filter(it => it.maximumClassSize - (it.allChildren.length - it.allAbsences.length) >= 1);
        }

        // Filtering for Terms
        if (termFilter) {
            ret = ret.filter(item => item.termId === termFilter);
        }

        return ret;
    }, [allItems, filterClassType, filterClassLocation, filterClassAvailability, canBookCourtesyClass, classTypeId, canSwapToClass, termFilter, filterDay, terms]);

    // Calculate the child's age in years and months, to enable finding suitable classStages
    const ageOfChild = useMemo(() => {
        return {
            months: moment().diff(storeChild?.dateOfBirth, 'months'),
            years: moment().diff(storeChild?.dateOfBirth, 'years')
        };
    }, [storeChild]);

    // Generate an Array of suitableClasses, based on the Child's age.
    const suitableClasses = useMemo(() => {
        // Find all the ClassStages the Child can join based on their age
        const suitableClassStages = classStages?.filter(classStage => {
            const startAgeMonths = (classStage.startAgeYears * 12) + (classStage.startAgeMonths);
            const endAgeMonths = (classStage.endAgeYears * 12) + (classStage.endAgeMonths);

            if (ageOfChild.months >= startAgeMonths && ageOfChild.months <= endAgeMonths) {
                return true;
            }
            return false;
        });
        const suitableClassStagesIds = suitableClassStages?.map(it => it.id);

        // Filter the items to only include those that are in the suitableClassStages
        let ret = items.filter(item => suitableClassStagesIds?.includes(item._classStageId ?? ''));

        // If we are booking a courtesy class, we only want to show classes with spaces in a lesson.
        if (canBookCourtesyClass) {
            ret = ret.filter(item => !!item._nextLessonDateWithSpacesAvailable);
        }

        return ret;
    }, [classStages, ageOfChild, items, canBookCourtesyClass]);

    const [joinCourtesyClassMessages, setJoinCourtesyClassMessages] = useState<JoinCourtesyClassMessages>({ errorMessage: undefined, warningMessage: undefined, successMessage: undefined });

    // Locations from suitableClasses
    const suitableLocations = useMemo(() => {
        const classes = suitableClasses.map(it => it.classLocationId).filter((value, index, self) => self.indexOf(value) === index);

        // Sort classes based on distance from member
        classes.sort((a, b) => {
            const distanceA = locationDistances?.find(locationDistance => locationDistance.classLocationId === a)?.distanceFromPoint;
            const distanceB = locationDistances?.find(locationDistance => locationDistance.classLocationId === b)?.distanceFromPoint;

            return parseFloat(distanceA ?? '1000') - parseFloat(distanceB ?? '1000');
        });

        return classes;
    }, [suitableClasses, locationDistances]);

    // Set the term filter to the current term if the child can self register, otherwise set it to the last term.
    useEffect(() => {
        if (!currentTerm || !terms) return;

        if (courtesyClass) {
            setTermFilter(courtesyClass.termId);
            return;
        }

        // If we are booking a courtesyClass we will work with the current term.
        if (canBookCourtesyClass) {
            setTermFilter(currentTerm?.id);
            return;
        }

        if (currentTerm.canSelfRegister) {
            setTermFilter(currentTerm?.id);
        } else {
            // Find the next term
            const nextTerm = terms.find(it => it.startDate > currentTerm.startDate);
            setTermFilter(nextTerm?.id);
        }
    }, [currentTerm, terms, canBookCourtesyClass, courtesyClass]);

    // Suitable classes from all location if given a classTypeId
    const suitableClassesFromAllLocations = useCallback((classTypeId: string) => {
        if (!classTypeId) return [];

        return suitableClasses.filter(it => it.classTypeId === classTypeId);
    }, [suitableClasses]);

    //const firstWeekOfTermDates = useMemo(() => {
    //    if (!currentTerm) return [];

    //    const dates = [];

    //    const startDate = moment(currentTerm.startDate);
    //    const endDate = moment(currentTerm.endDate);

    //    for (let i = 0; i < 7; i++) {
    //        const date = startDate.clone().add(i, 'days');
    //        if (date.isAfter(endDate)) break;

    //        dates.push(date.toISOString());
    //    }

    //    return dates;
    //}, [currentTerm]);

    //const lastWeekOfTermDates = useMemo(() => {
    //    if (!currentTerm) return [];

    //    const dates = [];

    //    const startDate = moment(currentTerm.startDate);
    //    const endDate = moment(currentTerm.endDate);

    //    for (let i = 0; i < 7; i++) {
    //        const date = endDate.clone().subtract(i, 'days');
    //        if (date.isBefore(startDate)) break;

    //        dates.push(date.toISOString());
    //    }

    //    return dates.reverse();
    //}, [currentTerm]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1 style={{ fontSize: '3rem' }}>
                                {title}
                            </h1>
                            <h3>{t('classFinderCore.subTitle', 'for {{firstName}} {{lastName}}', { firstName: storeChild?.firstName, lastName: storeChild?.lastName })}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid className="p-0">
                <>
                    <AlertOnErrors errors={[
                        loadErrors, currentTermLoadErrors,
                        supportingDataLoadErrors, memberProfileLoadErrors, locationDistancesLoadErrors
                    ]} />

                    <ConditionalFragment showIf={!!canBookCourtesyClass}>
                        <Alert color="warning">
                            <h5>{t('classFinderCore.info.title', 'Courtesy class policy')}</h5>
                            <ul>
                                <li>{t('classFinderCore.info.p1', 'Each bear cub can join a maximum of 2 courtesy classes per term.')}</li>
                                <li>{t('classFinderCore.info.p2', 'No courtesy classes are offered in the first or last days of a term.')}</li>
                                <li>{t('classFinderCore.info.p3', 'Courtesy classes cannot be carried over into another term.')}</li>
                                <li>{t('classFinderCore.info.p4', 'Courtesy classes are only offered where space is available in a suitable class.')}</li>
                                <li>{t('classFinderCore.info.p5', 'If you cannot attend a pre-arranged courtesy class it still counts as 1 of your 2 courtesy classes.')}</li>
                            </ul>
                        </Alert>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!isLoading}>
                        <Row>
                            <Col></Col>

                            <Col xs="auto" className="class-finder-core-loading-message">
                                <FontAwesomeIcon icon="magnifying-glass" color="#fada00" />
                                <> </>
                                {t('common.classFinderCore.loadingMessage', 'Searching our classes to find some suitable matches for your cub...')}
                                <> </>
                                <LoadingIndicator />
                            </Col>

                            <Col></Col>
                        </Row>
                    </ConditionalFragment>

                    {/* MoveChildModalResultMessages */}
                    <ConditionalFragment showIf={
                        !!joinCourtesyClassMessages.errorMessage ||
                        !!joinCourtesyClassMessages.warningMessage ||
                        !!joinCourtesyClassMessages.successMessage
                    }>
                        <Alert color={
                            joinCourtesyClassMessages.errorMessage ? 'danger' :
                                joinCourtesyClassMessages.warningMessage ? 'warning' : 'success'
                        }>
                            <ConditionalFragment showIf={!!joinCourtesyClassMessages.successMessage}>
                                {joinCourtesyClassMessages.successMessage}
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!joinCourtesyClassMessages.warningMessage}>
                                {joinCourtesyClassMessages.warningMessage}
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!joinCourtesyClassMessages.errorMessage}>
                                {joinCourtesyClassMessages.errorMessage}
                            </ConditionalFragment>
                        </Alert>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isLoading && !!filterClassType}>
                        <Row className="class-finder-core">
                            <Col xs={12} md={6} className="class-finder-core-header">
                                <CardImg
                                    alt="Card image cap"
                                    src={`/img/originals/${classTypes?.find(it => it.id === filterClassType)?.name.split(' ').join('')}Main.jpg`}
                                    top
                                    className="class-finder-core-header-img"
                                />
                                <CardImgOverlay>
                                    <CardTitle className="class-finder-core-header-title" tag="h5">
                                        <Row>
                                            <Col style={{ marginBottom: '0' }}>
                                                <p>{classTypes?.find(it => it.id === filterClassType)?.name}</p>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardImgOverlay>
                            </Col>
                        </Row>

                        <ConditionalFragment showIf={!suitableClasses.length}>
                            <Row>
                                <Col></Col>

                                <Col xs="auto" className="class-finder-core-no-suitable-classes-message">
                                    {t('classFinderCore.noSuitableClasses.message', 'Hello. Classes for your child’s age group may be unavailable. Please click the button below to contact us, and we’ll do our best to help find a suitable class for you or answer any questions you may have.')}
                                </Col>

                                <Col></Col>
                            </Row>

                            <Row>
                                <Col></Col>

                                <Col xs="auto" className="class-finder-core-no-suitable-classes-message">
                                    <Button color="primary">
                                        <a href="https://www.happycubs.ie/contact-us/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#737373', fontWeight: 'bold' }}>
                                            {t('common.anyQuestionsContactUs', 'Any Questions? Contact Us')}
                                        </a>
                                    </Button>
                                </Col>

                                <Col></Col>
                            </Row>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!suitableClasses.length}>
                            {
                                suitableLocations?.map(item => {
                                    const location = classLocations?.find(it => it.id === item);
                                    const classesForThisLocation = suitableClasses?.filter(it => it.classLocationId === item);
                                    const distance = locationDistances?.find(locationDistance => locationDistance.classLocationId === item)?.distanceFromPoint;

                                    return <ClassFinderCoreLocation
                                        locationId={item}
                                        location={location}
                                        suitableClasses={classesForThisLocation}
                                        distance={distance}
                                        childDateOfBirth={storeChild?.dateOfBirth}
                                        childId={childId}
                                        isForCourtesyClass={canBookCourtesyClass}
                                        courtesyClassCreditId={courtesyClassCreditId}
                                        isSwappingClass={canSwapToClass}
                                        oldScheduledClassChildId={oldScheduledClassChildId} />;
                                })
                            }
                        </ConditionalFragment>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isLoading && !filterClassType}>
                        <Row className="class-finder-core">
                            {
                                classTypes?.map((classType, index) => {
                                    return (
                                        <Col xs={12} className="class-finder-core-header">
                                            <div style={{ position: 'relative', }}>
                                                <CardImg
                                                    alt="Card image cap"
                                                    src={`/img/originals/${classType.name.split(' ').join('')}Main.jpg`}
                                                    top
                                                    className="class-finder-header-img"
                                                />
                                                <CardImgOverlay>
                                                    <CardTitle tag="h5" className="class-finder-core-header-title">
                                                        <Row>
                                                            <Col style={{ marginBottom: '0' }}>
                                                                <p>{classType.name}</p>
                                                            </Col>
                                                        </Row>
                                                    </CardTitle>
                                                </CardImgOverlay>
                                            </div>

                                            <ConditionalFragment showIf={!suitableClassesFromAllLocations(classType.id)?.length}>
                                                <NoResultsFound />
                                            </ConditionalFragment>

                                            <ConditionalFragment showIf={!!suitableClassesFromAllLocations(classType.id)?.length}>
                                                {suitableLocations?.map(it => {
                                                    const location = classLocations?.find(location => location.id === it);
                                                    const classesForThisLocation = suitableClasses.filter(suitableClass => suitableClass.classLocationId === it && suitableClass.classTypeId === classType.id);
                                                    const distance = locationDistances?.find(locationDistance => locationDistance.classLocationId === it)?.distanceFromPoint;

                                                    if (classesForThisLocation.length === 0) return null;

                                                    return <ClassFinderCoreLocation
                                                        locationId={it}
                                                        location={location}
                                                        suitableClasses={classesForThisLocation}
                                                        distance={distance}
                                                        childDateOfBirth={storeChild?.dateOfBirth}
                                                        childId={childId} />;
                                                })}

                                                {/* Quick and dirty way to not show on the last one. We only have two class types. */}
                                                <ConditionalFragment showIf={index === 0}>
                                                    <div style={{ margin: '4rem 0' }}>
                                                        <HorizontalRule />
                                                    </div>
                                                </ConditionalFragment>
                                            </ConditionalFragment>

                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </ConditionalFragment>
                </>
            </MainContainer>
        </>
    );
};
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { useStartReenrolmentMutation } from "../../api/main/startReenrolments/useStartReenrolmentMutation";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TwoValueSwitch } from "../shared/twoValueSwitch/TwoValueSwitch";
import { useReenrolmentViewModel } from "../../api/main/reenrolments/viewModels/useReenrolmentViewModel";
import { BackgroundTaskStatus } from "../../api/main/models/constants/BackgroundTaskStatus";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCancelBackgroundTaskMutation } from "../../api/main/backgroundTasks/useCancelBackgroundTaskMutation";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { AddToReenrolmentModal } from "./AddToReenrolmentModal";

export interface ReenrolmentModalResultMessages {
    errorMessage: string | undefined | null, warningMessage: string | undefined | null, successMessage: string | undefined | null;
}

/**
 * Component that lets an administrator manage the reenrolment process.
 */
export const Reenrolment = () => {
    const { t } = useTranslation();

    const {
        data: { nextTerm, latestBackgroundTask: _latestBackgroundTask, },
        isLoading,
        errors: loadErrors,
        refresh,
    } = useReenrolmentViewModel();

    // We're only counting the _latestBackgroundTask as relevant if it covered this term.
    const latestBackgroundTask = useMemo(() => {
        // If we don't have a task, just return it.
        if (!_latestBackgroundTask) {
            return _latestBackgroundTask;
        }

        // Check if the arguments contain our nextTerm id.  If it does not, exclude the task from consideration.
        if ((_latestBackgroundTask.argumentsJson?.toLowerCase() ?? '').indexOf(nextTerm?.id?.toLowerCase() ?? '') === -1) {
            return undefined;
        }

        // If we get here the task is for our term, so use it.
        return _latestBackgroundTask;
    }, [_latestBackgroundTask, nextTerm]);

    // Toggle to know that the user has confirmed they want to continue.
    const [iveReadChecked, toggleIveRead] = useToggleState();

    // While we're on this screen, we want to refresh the task status on a short interval.
    // NOTE we don't limit this to happening only when we think we have relevant task, because if someone else triggers it we can
    // still update the screen.
    useEffect(() => {
        // Start an interval timer.
        const timer = setInterval(() => {
            refresh();
        }, 20 * 1000);

        // Cleanup function.
        return () => clearInterval(timer);
    }, [refresh]);

    // Allow the cancelling of the current task.
    const [cancelBackgroundTaskMutation, { errors: cancelBackgroundTaskMutationErrors }] = useCancelBackgroundTaskMutation();
    const [cancelBackgroundTask, { isExecuting: isCancellingBackgroundTask, errors: cancelBackgroundTaskErrors }] = useAsyncCallback(async () => {
        // If we don't have a task, do nothing.
        if (!latestBackgroundTask) {
            return;
        }

        // Try cancel on the server.  This will only work if we haven't started executing the task.
        await cancelBackgroundTaskMutation(latestBackgroundTask.id);

        refresh();
    }, [latestBackgroundTask, cancelBackgroundTaskMutation, refresh]);

    // Showing of the ReenrolmentModal
    const [reenrolmentModalIsOpen, _toggleReenrolmentModal] = useToggleState();
    const toggleReenrolmentModal = useCallback(() => {
        _toggleReenrolmentModal();
    }, [_toggleReenrolmentModal]);

    // Message state from the various actions.
    const [actionMessages, setActionMessages] = useState<ReenrolmentModalResultMessages>({ errorMessage: undefined, warningMessage: undefined, successMessage: undefined });

    // Manage the SelectedClassIds
    const [selectedClassIds, setSelectedClassIds] = useState<Array<string>>([]);

    // Handle adding classes for re-enrolment
    const [onReenrolmentModalClosed, { isExecuting: isAddingClassesForReenrolment, errors: addClassesForReenrolmentErrors }] = useAsyncCallback(async (event: { selectedIds: Array<string>, cancelled: boolean; }) => {
        if (event.cancelled || !event.selectedIds || !event.selectedIds.length) {
            return;
        }

        // Set the state for the returned classIds
        setSelectedClassIds(event.selectedIds);
    }, [setSelectedClassIds, t]);

    // Perform the reenrolment process by starting the task on the server.
    const [startReenrolmentMutation, { errors: startReenrolmentMutationErrors, }] = useStartReenrolmentMutation();
    const [startReenrolment, { isExecuting: isStartingReenrolment, errors: startReenrolmentErrors, }] = useAsyncCallback(async () => {
        // If we don't have a term to work on, do nothing.
        if (!nextTerm || selectedClassIds.length < 1) {
            return;
        }

        // Start the reenrolment mutation, this will start a background task.
        await startReenrolmentMutation(selectedClassIds, nextTerm.id);

        // Show a success message
        setActionMessages({
            successMessage: t('reenrolment.addClassesForReenrolment.message', '{{count}} {{classOrClasses}} {{haveOrHas}} been sent to re-enrolment', { count: selectedClassIds.length, classOrClasses: selectedClassIds.length === 1 ? 'class' : 'classes', haveOrHas: selectedClassIds.length === 1 ? 'has' : 'have' }),
            warningMessage: undefined,
            errorMessage: undefined,
        });

        // Clear the selected classIds
        setSelectedClassIds([]);
    }, [startReenrolmentMutation, nextTerm, refresh, setActionMessages, selectedClassIds]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('reenrolment.title', 'Re-enrolment process for {{term}}', { term: nextTerm?.name, })}
                            </h1>
                            <div className="text-muted">
                                {t(
                                    'reenrolment.subTitle',
                                    'The next term will start in {{weekCount, 0}} weeks on {{date, dddd MMMM Do YYYY}}',
                                    {
                                        date: moment(nextTerm?.startDate),
                                        weekCount: moment(nextTerm?.startDate).diff(moment(), 'weeks')
                                    })}
                            </div>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        startReenrolmentErrors, startReenrolmentMutationErrors,
                        cancelBackgroundTaskErrors, cancelBackgroundTaskMutationErrors,
                        addClassesForReenrolmentErrors
                    ]}
                />
                <ConditionalFragment showIf={!!selectedClassIds.length}>
                    <Alert color="warning">
                        {t('reenrolment.addClassesForReenrolment.warningMessage', '{{count}} {{classOrClasses}} {{haveOrHas}} been selected for re-enrolment', { count: selectedClassIds.length, classOrClasses: selectedClassIds.length === 1 ? 'class' : 'classes', haveOrHas: selectedClassIds.length === 1 ? 'has' : 'have' }) }
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={
                    !!actionMessages.errorMessage ||
                    !!actionMessages.warningMessage ||
                    !!actionMessages.successMessage
                }>
                    <Alert color={
                        actionMessages.errorMessage ? 'danger' :
                            actionMessages.warningMessage ? 'warning' : 'success'
                    }>
                        <ConditionalFragment showIf={!!actionMessages.successMessage}>
                            {actionMessages.successMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!actionMessages.warningMessage}>
                            {actionMessages.warningMessage}
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!actionMessages.errorMessage}>
                            {actionMessages.errorMessage}
                        </ConditionalFragment>
                    </Alert>
                </ConditionalFragment>

                {
                    latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Queued ? (
                        <Alert color="info">
                            <Row>
                                <Col xs="auto" style={{ fontSize: '1.5rem', }}>
                                    <FontAwesomeIcon icon="clock" />
                                </Col>
                                <Col>
                                    <p style={{ fontWeight: 'bold', }}>
                                        {t('reenrolment.taskAlert.queued.p1', 'Re-enrolement for next term is queued on to begin processing as soon as possible.')}
                                    </p>
                                    <p>
                                        {t('reenrolment.taskAlert.queued.p2', 'The re-enrolement task is likely to take some time. This screen will be updated automatically. You do not need to wait on this screen for the task to run it will continue in the background as you do other things.')}
                                    </p>
                                </Col>
                                <Col xs={12} md="auto">
                                    <ButtonAsync color="info" outline
                                        isExecuting={isCancellingBackgroundTask}
                                        executingChildren={<><Spinner size="sm" /> {t('reenrolment..executingText', 'Cancelling...')}</>}
                                        onClick={cancelBackgroundTask}
                                    >
                                        <FontAwesomeIcon icon="times" />
                                        <> </>
                                        {t('reenrolment.cancelButton.text', 'Cancel')}
                                    </ButtonAsync>
                                </Col>
                            </Row>
                        </Alert>
                    ) : latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Executing ? (
                        <Alert color="info">
                            <Row>
                                <Col xs="auto" style={{ fontSize: '1.5rem', }}>
                                    <FontAwesomeIcon icon="hourglass" />
                                </Col>
                                <Col>
                                    <p style={{ fontWeight: 'bold', }}>
                                        {t('reenrolment.taskAlert.executing.errors.p1', 'Re-enrolement steps for next term are in progress.  Processing started at {{date, DD/MM/YYYY HH:mm}}.', { date: moment(latestBackgroundTask.startedDate) })}
                                    </p>
                                    <p>
                                        {t('reenrolment.taskAlert.executing.p2', 'The re-enrolement task is likely to take some time. This screen will be updated automatically. You do not need to wait on this screen for the task to run it will continue in the background as you do other things.')}
                                    </p>
                                </Col>
                            </Row>
                        </Alert>
                    ) : latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Completed && !!latestBackgroundTask.executionErrors ? (
                        <Alert color="danger">
                            <Row>
                                <Col xs="auto" style={{ fontSize: '1.5rem', }}>
                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                </Col>
                                <Col>
                                    <p style={{ fontWeight: 'bold', }}>
                                        {t('reenrolment.taskAlert.completed.errors.p1', 'Re-enrolement for next term failed to complete. Processing was attempted between {{startDate, DD/MM/YYYY HH:mm}} and {{completedDate, DD/MM/YYYY HH:mm}}.', { startDate: moment(latestBackgroundTask.startedDate), completedDate: moment(latestBackgroundTask.completedDate), })}
                                    </p>
                                    <p>
                                        {t('reenrolment.taskAlert.completed.errors.p2', 'Error reason:')}
                                        <> </>
                                        {latestBackgroundTask.executionErrors}
                                    </p>
                                </Col>
                            </Row>
                        </Alert>
                    ) : null
                }

                <h5>{t('reenrolment.before.title', 'Before you start')}</h5>
                <ul>
                    <li>{t('reenrolment.before.p1', 'Ensure all the classes you are expecting to run next term are configured with the correct next term details.')}</li>
                    <li>{t('reenrolment.before.p2', 'Ensure any classes that will be ending next term have been set to have no classes next term.')}</li>
                    <li>{t('reenrolment.before.p3', 'Ensure all cubs have been added to their appropriate class for next term.')}</li>
                    <li>{t('reenrolment.before.p4', 'Ensure you have selected the classes you would like to send for re-enrolment using the "Add classes" button.')}</li>
                    <li><strong>{t('reenrolment.before.p5', 'Please keep in mind that when you start re-enrolment, it is not easy to reverse this process as Mama and Papa bears will be contacted and asked to make payments.')}</strong></li>
                </ul>

                <h5>{t('reenrolment.whatWillHappen.title', 'What will happen?')}</h5>
                <ul>
                    <li>{t('reenrolment.whatWillHappen.p1', 'New outstanding payments will be added for each cub who will be participating in a classes next term.')}</li>
                    <li>{t('reenrolment.whatWillHappen.p2', 'Each cub\'s Mama or Papa bear will be emailed with a link to pay for their classes to re-enrole for the next term.')}</li>
                    <li>{t('reenrolment.whatWillHappen.p3', 'Future reminder emails will be scheduled for Mama and Papa bears who have not paid to remind them of the need to pay.')}</li>
                    <li>{t('reenrolment.whatWillHappen.p4', 'You are likely to see an increase in incoming messages from Mama and Papa bears during re-enrolement. Remember to direct them to the self service features available to them on the Happy Cubs site.')}</li>
                    <li>{t('reenrolment.whatWillHappen.p5', 'The classes for next term will be based around the responses received during the re-enrolement process. Once the payment period is over you can make any final changes to the classes.')}</li>
                </ul>

                <Alert color="info">
                    <TwoValueSwitch
                        leftLabel=""
                        rightLabel={t('reenrolment.iveReadChecked.rightText', 'I confirm I have read and understood the above information and understand the impact of starting re-enrolment.')}
                        checked={iveReadChecked}
                        onChange={checked => toggleIveRead(checked)}
                    />
                </Alert>

                <Row>
                    <Col></Col>
                    <Col xs="auto">
                        <ButtonAsync color="primary" disabled={!iveReadChecked || latestBackgroundTask?.taskStatus === BackgroundTaskStatus.Queued || !selectedClassIds.length}
                            isExecuting={isStartingReenrolment}
                            executingChildren={<><Spinner size="sm" /> {t('reenrolment.startButton.executingText', 'Starting...')}</>}
                            onClick={startReenrolment}
                        >
                            <FontAwesomeIcon icon="circle-play" />
                            <> </>
                            {t('reenrolment.startButton.text', 'Start re-enrolement')}
                        </ButtonAsync>
                    </Col>
                </Row>

                {/* Re-enrolment Modal */}
                <FormGroup>
                    <Button color="primary" onClick={() => toggleReenrolmentModal()}>
                        <FontAwesomeIcon icon="plus" />
                        <> </>
                        {t('reenrolment.addClasses', 'Add classes')}
                    </Button>

                    <ConditionalFragment showIf={reenrolmentModalIsOpen}>
                        <StyledModal
                            isOpen={reenrolmentModalIsOpen}
                            toggle={toggleReenrolmentModal}
                            size="xl">
                            <AddToReenrolmentModal
                                toggle={toggleReenrolmentModal}
                                onClose={onReenrolmentModalClosed}
                                isSaving={isAddingClassesForReenrolment}
                            />

                        </StyledModal>
                    </ConditionalFragment>
                </FormGroup>
            </MainContainer>
        </>
    );
};
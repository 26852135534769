import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { TermFieldsFragment } from '../generated/graphql';

export const termFieldsFragment = gql`
    fragment termFields on Term {
        id
        name
        archived
        startDate
        endDate
        reenrolmentProcessedDate
        canSelfRegister
        copiedClassesOnDate
    }
`;

export type Term = Omit<TermFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const termDefaultValues = (): Partial<Term> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().startOf('day').add('day', 7).toISOString(),
    canSelfRegister: false,
    copiedClassesOnDate: null,
});
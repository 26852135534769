import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ScheduledClassFieldsFragment } from '../generated/graphql';

export const scheduledClassFieldsFragment = gql`
    fragment scheduledClassFields on ScheduledClass {
        id
        classLocationId
        dayOfWeek
        startTimeHours
        startTimeMinutes
        endTimeHours
        endTimeMinutes
        classTypeId
        currentClassStageId
        currentClassSubStageId
        currentLessonNumber
        nextClassStageId
        nextClassSubStageId
        archived
        maximumClassSize
        lessonsPerTerm
        costPerLesson
        termId
        originKey
        reenrolmentProcessedDate
        reenrolmentClosedDate
    }
`;

export type ScheduledClass = Omit<ScheduledClassFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const scheduledClassDefaultValues = (): Partial<ScheduledClass> => ({
    id: Guid.newGuid(),
    classLocationId:undefined,
    dayOfWeek: 0,
    startTimeHours: 0,
    startTimeMinutes: 0,
    endTimeHours: 0,
    endTimeMinutes: 0,
    classTypeId: undefined,
    currentClassStageId: null,
    currentClassSubStageId: null,
    currentLessonNumber: 1,
    nextClassStageId: null,
    nextClassSubStageId: null,
    archived: false,
    maximumClassSize: 0,
    lessonsPerTerm: 10,
    costPerLesson: 0
});
import { Button, Row, Col, Spinner, Alert, FormGroup, Input, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { Term, termDefaultValues } from '../../../api/main/models/Term';
import { useSaveTermMutation } from '../../../api/main/terms/useSaveTermMutation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MainTab } from "./MainTab";
import { useEditTermViewModel } from '../../../api/main/terms/viewModels/useEditTermViewModel';
import { TermHoliday } from '../../../api/main/models/TermHoliday';
import { useGenerateScheduledClassesMutation } from '../../../api/main/scheduledClasses/viewModels/useGenerateScheduledClassesMutation';
import moment from "moment";
import { useNextTerm } from '../../../api/main/terms/useNextTerm';
import { DayOfWeek } from '../../../api/main/models/constants/DayOfWeek';
import "./editTerm.scss";
import { useCreateTermHolidaysForLocationMutation } from '../../../api/main/termHolidays/useCreateTermHolidaysForLocationMutation';
import { DatesTable } from './DatesTable/DatesTable';
import { StyledModal } from '../../shared/styledModal/StyledModal';
import { useToggleState } from 'use-toggle-state';
import { TwoValueSwitch } from '../../shared/twoValueSwitch/TwoValueSwitch';

export type TableDataItem = {
    dayOfWeek: string,
    lessonDates: Array<Date>,
    holidayDates: Array<Date>,
    changed: boolean,
};

export interface EditTermProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Term>;
}

/**
 * Component to create an Term.
 */
export const CreateTerm = (props: EditTermProps) => (<EditTerm isCreate={true} {...props} />);

/**
 * Component to edit an Term.
 */
export const EditTerm = (props: EditTermProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load all data.
    const {
        data: {
            model: storeModel,
            termHolidays: storeTermHolidays,
            classLocations: storeClassLocations,
            terms: storeTerms,
        },
        errors: loadErrors, isLoading: isLoadingData,
        refresh,
    } = useEditTermViewModel(id);

    // Load the next term
    const {
        data: {
            model: nextTerm
        }, isLoading: isLoadingNextTerm, errors: loadNextTermErrors
    } = useNextTerm();
    const isLoading = isLoadingData || isLoadingNextTerm;

    // State for classLocation
    const [classLocationId, setClassLocationId] = useState<string>('');
    const [classLocationsToCopyTo, setClassLocationsToCopyTo] = useState<Array<string>>([]);
    const [confirmRegistrationOpen, toggleConfirmRegistrationOpen] = useToggleState();

    // Model (Term)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...termDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveTerm, { errors: saveErrors }] = useSaveTermMutation();

    // Term Holidays
    const termHolidaysManager = useChangesArray<TermHoliday, string>(storeTermHolidays, item => item.id);
    const [saveTermHolidaysForLocation, { errors: saveTermHolidaysForLocationErrors }] = useCreateTermHolidaysForLocationMutation();

    // Main model validation (including all related objects).
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editTerm.errors.name', 'Name cannot be empty') : '',
            startDate: () => !model?.startDate ? t('editTerm.errors.startDate', 'Start date cannot be empty') : '',
            endDate: () => !model?.endDate ? t('editTerm.errors.endDate', 'End date cannot be empty') : '',

            // Any errors in the related objects.
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { dontNavigate?: boolean, }) => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveTerm(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        if (options?.dontNavigate === false || !options?.dontNavigate) {
            navigate(-1);
        }
    }, [validate, saveTerm, model, changes, isCreate, id, navigate, termHolidaysManager,]);

    // Get the previous term
    const previousTerm = useMemo(() => {
        // Return the term that precedes this term
        if (!storeTerms || !model) return;

        // Get this terms index in the storeTerms array
        const thisTermIndex = storeTerms.findIndex((it: Term) => it.id === model.id);

        // Get the term prior to this term
        const previousTerm = storeTerms[thisTermIndex - 1];

        // Return the previous term
        return previousTerm;
    }, [model, storeTerms]);

    // Generate classes from the current term
    const [generateClasses, { isExecuting: isGeneratingClasses, errors: generateClassesErrors }] = useGenerateScheduledClassesMutation();
    const [handleGenerateClasses] = useAsyncCallback(async () => {
        if (!previousTerm?.id) return;

        // Save the form first if we're creating a new term.
        if (isCreate) {
            await saveForm({ dontNavigate: true });
        }

        // Generate the classes.
        if (!isSaving) {
            await generateClasses(previousTerm?.id, model.id);
        }
    }, [previousTerm?.id]);

    // State for tableData
    const [tableData, setTableData] = useState<Array<TableDataItem>>([]);


    // Save everything.
    const [saveTermHolidays, { isExecuting: isSavingTermHolidaysForLocation, errors: saveTermHolidaysForLocationFormErrors }] = useAsyncCallback(async (termId: string, locationId: string) => {
        if (!tableData || !tableData.length) {
            return;
        }

        const allHolidayDates = tableData?.map(it => it.holidayDates).flat();

        await saveTermHolidaysForLocation(termId, locationId, allHolidayDates);

        // Set all changed to false
        setTableData(tableData.map(it => ({ ...it, changed: false })));

        refresh();
    }, [tableData, refresh]);

    // Build table data
    const [generatedDatesForLocationId, setGeneratedDatesForLocationId] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (!model) return;

        if (!classLocationId) { return; }

        // If we've alreadygenerated for this location, we manage the state elsewhere so don't repeat.
        if (classLocationId === generatedDatesForLocationId) { return; }


        // If we get here we are going to generate the class records.
        //
        setGeneratedDatesForLocationId(classLocationId);

        const myHolidays = termHolidaysManager.model.filter(it => it.classLocationId === classLocationId);

        // Result 
        const result: Array<TableDataItem> = [];

        // Get the days of the week from DayOfWeek enum
        const daysOfWeek = Object.keys(DayOfWeek).filter(value => !(parseInt(value) >= 0));

        // Dates
        let currentDate = new Date(model.startDate);
        const endDate = new Date(model.endDate);

        // Loop through the dates
        while (currentDate <= endDate) {
            const dayOfWeek = daysOfWeek[currentDate.getDay()]; // Get the day of the week
            const formattedDate = new Date(currentDate); // Format the date

            // Check if this is during a holiday
            const isHoliday = !!myHolidays.find(it => new Date(it.startDate) <= currentDate && currentDate <= new Date(it.endDate));

            // Check if an entry for the dayOfWeek already exists.  Otherwise make one.
            let existingEntry = result.find(entry => entry.dayOfWeek === dayOfWeek);
            if (!existingEntry) {
                existingEntry = { dayOfWeek, lessonDates: [], holidayDates: [], changed: false, };
                result.push(existingEntry);
            }

            // Add the date to the existing entry
            if (isHoliday) {
                existingEntry.holidayDates.push(formattedDate);
            } else {
                // We only want to add the date here if it doesn't already exist
                if (!existingEntry.lessonDates.find(it => it.getTime() === formattedDate.getTime())) {
                    existingEntry.lessonDates.push(formattedDate);
                }
            }

            // Increment the date
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Sort the dates
        result.forEach(entry => {
            entry.lessonDates.sort((a: Date, b: Date) => a.getTime() - b.getTime());
        });

        // Set the result
        setTableData(result);
    }, [model, classLocationId, termHolidaysManager, generatedDatesForLocationId, setGeneratedDatesForLocationId]);

    // Copy to ClassLocation selector modal
    const [isModalOpen, toggleIsModalOpen] = useToggleState(false);

    // Copy class alert
    const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

    // Handle adding classes to be copied to
    const handleAddingClassLocationToCopyTo = useCallback((locationId: string) => {
        const isSelected = classLocationsToCopyTo.includes(locationId);
        if (isSelected) {
            const updatedClassLocationsToCopyTo = classLocationsToCopyTo.filter(it => it !== locationId);
            setClassLocationsToCopyTo(updatedClassLocationsToCopyTo);
        } else {
            setClassLocationsToCopyTo([...classLocationsToCopyTo, locationId]);
        }

    }, [classLocationsToCopyTo]);

    // Handle copy class
    const [handleCopyClass, { isExecuting: isCopingClass, errors: handleCopyClassErrors }] = useAsyncCallback(async () => {
        for (const locationId of classLocationsToCopyTo) {
            await saveTermHolidays(model.id, locationId);
        }

        setClassLocationsToCopyTo([]);
        toggleIsModalOpen();
        setIsAlertVisible(true);
        setTimeout(() => setIsAlertVisible(false), 5000);
    }, [model, classLocationsToCopyTo, saveTermHolidays, toggleIsModalOpen]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                    isCreate ? t('editTerm.createHeading.default', 'Add term')
                                        : t('editTerm.editHeading.default', 'Edit term')
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors,
                    generateClassesErrors,
                    loadNextTermErrors,
                    saveTermHolidaysForLocationErrors, saveTermHolidaysForLocationFormErrors,
                    handleCopyClassErrors
                ]} />

                {/* Alerts */}
                <ConditionalFragment showIf={model?.id === nextTerm?.id && !model.copiedClassesOnDate}>
                    <Alert color="warning">
                        {t('editTerm.genratingClassesMessage', 'Generating classes will use the current term "{{termName}}" as the basis.', { termName: previousTerm?.name })}
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={model?.id !== nextTerm?.id}>
                    <Alert color="warning">
                        {t('editTerm.unableToGenerateClassesMessage', 'Classes can only be generated for the next term "{{nextTerm}}".', { nextTerm: nextTerm?.name })}
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={isGeneratingClasses}>
                    <Alert color="warning">
                        {t('editTerm.generatingClasses.warningMessage', 'Generating classes based on the current term "{{termName}}". This process may take some time.', { termName: previousTerm?.name })}
                    </Alert>
                </ConditionalFragment>

                <ConditionalFragment showIf={model.copiedClassesOnDate}>
                    <Alert color="warning">
                        {t('editTerm.generatedClassesOn.dateTime', 'The classes for this term were generated on {{date, DD/MM/YYYY}} at {{date, HH:mm}}', { date: moment(model.copiedClassesOnDate) })}
                    </Alert>
                </ConditionalFragment>

                {/* MainTab */}
                <MainTab
                    model={model}
                    change={change}
                    validate={validate}
                    validationErrors={validationErrors}
                    saveForm={saveForm}
                />

                <ConditionalFragment showIf={!!model.canSelfRegister}>
                    <Alert color="warning">
                        <TwoValueSwitch
                            leftLabel=""
                            rightLabel={t('editTerm.confirmRegistrationOpen.warning', 'I confirm that I want to set registration to open for this term, this isn\'t required for re-enrolments and should only be set to open after re-enrolments have closed to allow all users to search for and join classes.')}
                            checked={confirmRegistrationOpen}
                            onChange={checked => toggleConfirmRegistrationOpen(checked)}
                        />
                    </Alert>
                </ConditionalFragment>

                {/* Save buttons at the bottom of all tabs. */}
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()} disabled={model.canSelfRegister && !confirmRegistrationOpen}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>

                <h3 className="edit-term-subheading">{t('editTerm.holidaysAndLocations.subHeading', 'Holidays and locations')}</h3>

                <ConditionalFragment showIf={!tableData?.every(it => !it.changed)}>
                    <Alert color="danger">
                        {t('editTerm.alertSaveChanges.message', 'You have unsaved changes for {{ location }}, please use the "Save for location" button before leaving this screen.', { location: storeClassLocations?.find(it => it.id === classLocationId)?.name })}
                    </Alert>
                </ConditionalFragment>

                {/* Location Selector */}
                <Row>
                    <Col>
                        <FormGroup>
                            <Input name="classLocation" type="select" value={classLocationId ?? ''} onChange={e => setClassLocationId(e.currentTarget.value)} disabled={tableData?.some(it => it.changed)}>
                                <option value="">{t('common.pleaseSelect.location', '(Please select a location)')}</option>
                                {storeClassLocations?.map(location => (
                                    <option value={location.id}>{location.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col style={{ display: 'flex', flexDirection: 'column' }} xs="auto">
                        <Button color="secondary" outline
                            onClick={() => toggleIsModalOpen()}
                            disabled={classLocationId === '' || tableData?.some(it => it.changed)}>
                            {t('common.copyThisLocation', 'Copy this location')}
                        </Button>
                    </Col>

                    <Col style={{ display: 'flex', flexDirection: 'column' }} xs="auto">
                        <ButtonAsync color="primary" outline isExecuting={isSavingTermHolidaysForLocation}
                            executingChildren={<><Spinner size="sm" /> {t('common.savingForLocation', 'Saving for location...')}</>}
                            disabled={classLocationId === '' || tableData?.every(it => !it.changed)}
                            onClick={() => saveTermHolidays(model.id, classLocationId)}>
                            {t('common.saveForLocation', 'Save for location')}
                        </ButtonAsync>
                    </Col>
                </Row>

                <ConditionalFragment showIf={isAlertVisible}>
                    <Alert color="success">
                        {t('editTerm.alertCopyClass.message', 'Class copied succesfully')}
                    </Alert>
                </ConditionalFragment>

                {/* Dates Table */}
                <ConditionalFragment showIf={classLocationId !== ''}>
                    <DatesTable
                        tableData={tableData}
                        setTableData={setTableData}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={model?.id === nextTerm?.id && !model.copiedClassesOnDate}>
                    <Row>
                        <Col>
                            <FormButtons className="text-start m-0">
                                <ButtonAsync color="primary" outline isExecuting={isGeneratingClasses}
                                    onClick={() => handleGenerateClasses()}
                                    executingChildren={<><Spinner size="sm" /> {t('editTerm.generatingClasses.button', 'Generating classes...')}</>}
                                >
                                    <FontAwesomeIcon icon="copy" />
                                    <> {t('editTerm.generateClasses.button', 'Generate classes')}</>
                                </ButtonAsync>
                            </FormButtons>
                        </Col>
                    </Row>
                </ConditionalFragment>

                {/* Copy to ClassLocation selector modal */}
                <StyledModal
                    isOpen={isModalOpen}
                    toggle={() => toggleIsModalOpen()}
                    size="lg">
                    <ModalHeader toggle={() => toggleIsModalOpen()}>
                        {t('common.copying', 'Copying')} {storeClassLocations?.find(it => it.id === classLocationId)?.name}
                    </ModalHeader>

                    <ModalBody>
                        <FormGroup>
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>{t('common.location', 'Location')}</th>
                                        <th>{t('common.selected', 'Selected')}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {storeClassLocations?.filter(it => it.id !== classLocationId).map(location => (

                                        <tr>
                                            <td>{location.name}</td>
                                            <td><Input type="checkbox" checked={classLocationsToCopyTo.includes(location.id)} onClick={() => handleAddingClassLocationToCopyTo(location.id)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter>
                        <ButtonAsync color="primary" isExecuting={isCopingClass}
                            executingChildren={<><Spinner size="sm" /> {t('common.copyingThisLocation', 'Copying this location...')}</>}
                            disabled={!classLocationsToCopyTo.length}
                            onClick={() => handleCopyClass()}>
                            {t('common.copy', 'Copy')}
                        </ButtonAsync>
                        <Button color="primary" outline onClick={() => { setClassLocationsToCopyTo([]); toggleIsModalOpen(); }}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </ModalFooter>
                </StyledModal>
            </MainContainer>
        </>
    );
};

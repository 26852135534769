import * as React from "react";
import { FormGroup, Label } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback, ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Profile as ProfileModel, profileDefaultValues } from '../../api/main/models/Profile';
import { ValidationErrors } from "pojo-validator";
import { useRegisterProfileMutation } from "../../api/main/profiles/useRegisterProfileMutation";
import { ProfileCreateInput } from "../../api/main/generated/graphql";
import { useGendersForAnonymousUser } from "../../api/main/genders/useGendersForAnonymousUser";
import { ConditionalFragment } from "react-conditionalfragment";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { useSaveMemberMutation } from "../../api/main/members/useSaveMemberMutation";

export interface RegisterProfileDetailsProps {
    // Public
    registerProfile: (userId: string) => Promise<void>,
    isRegisteringProfile: boolean,
    registerProfileErrors: any,

    validate: ValidateCallback,
    validationErrors: ValidationErrors

    // Private
    model: ProfileModel,
    change: (changes: Partial<ProfileModel>) => void,
    changes: Partial<ProfileModel>
}

/**
 * Custom hook that lets users of RegisterProfileDetails own the state of the Profile without being aware of its format.
 * @param userId
 */
export function useRegisterProfileDetailsProps(): RegisterProfileDetailsProps {
    const { t } = useTranslation();

    const [_registerProfile, { isExecuting: isRegisteringProfile, errors: registerProfileErrors }] = useRegisterProfileMutation();
    const { model, change, changes } = useChanges<ProfileModel>(null, { ...profileDefaultValues(), });

    const [createMember] = useSaveMemberMutation();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            firstName: () => !model.firstName ? t('registerProfileDetails.firstNameRequired', 'First name is required') : '',
            lastName: () => !model.lastName ? t('registerProfileDetails.lastNameRequired', 'Last name is required') : '',
            primaryPhone: () => !model.primaryPhone ? t('registerProfileDetails.primaryPhoneRequired', 'Primary phone is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const registerProfile = React.useCallback(async (userId: string) => {
        await _registerProfile({ ...model, userId: userId, isMember: true } as ProfileCreateInput);

        await createMember(model.id, { id: model.id, userId: userId, archived: false, registeredDate: new Date().toISOString(), photoBlobReferenceId: null, optInToMarketing: false, optInToMarketingUpdatedDate: new Date().toISOString(), applyStaffDiscount: false }, true);
    }, [_registerProfile, model, createMember]);

    return {
        registerProfile: registerProfile,
        isRegisteringProfile: isRegisteringProfile,
        registerProfileErrors: registerProfileErrors,

        validate: validate,
        validationErrors: validationErrors,

        model: model,
        change: change,
        changes: changes,
    };
};


/**
 * Allow the user to set their personal details on their profile during registration.
 */
export const RegisterProfileDetails = (props: RegisterProfileDetailsProps & { children?: React.ReactNode }) => {
    const { model, change, validate, validationErrors, children, } = props;
    const { t } = useTranslation();

    // Load the gender data
    const {
        data: {
            items: genders
        }, isLoading,
    } = useGendersForAnonymousUser();
    
    // Render the UI.
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="firstName">{t('profileDetails.firstName', 'First name')}</Label>
                <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="lastName">{t('profileDetails.lastName', 'Last name')}</Label>
                <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
            </FormGroup>

            <FormGroup>
                <Label htmlFor="primaryPhone">{t('register.primaryPhone', 'Primary phone')}</Label>
                <ValidatedInput name="primaryPhone" type="text" value={model.primaryPhone ?? ''} onChange={e => change({ primaryPhone: e.currentTarget.value })} onBlur={e => validate('primaryPhone')} validationErrors={validationErrors['primaryPhone']} />
            </FormGroup>

            <FormGroup>
                <ConditionalFragment showIf={!!isLoading}>
                    <LoadingIndicator />
                </ConditionalFragment>
                <Label htmlFor="genderId">{t('profileDetails.genderId.label', 'Gender')}</Label>
                <ValidatedInput name="genders" type="select" value={model?.genderId ?? ''} onChange={e => change({ genderId: e.currentTarget.value })} onBlur={e => validate('genderId')} validationErrors={validationErrors['genderId']}>
                    <option value="">{t('profileDetails.genderId.pleaseSubmit', '(Please select a gender)')}</option>
                    {
                        genders?.map(item => (
                            <option key={item.id} value={item.id}>{t('profileDetails.genderId.value', '{{ gender }}', { gender: item.name })}</option>
                        ))
                    }
                </ValidatedInput>
            </FormGroup>

            {children}
        </>
    );
};
